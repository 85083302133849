import Button from "@/components/Common/Buttons/Button/Button";
import InputField from "@/components/Common/Inputs/Input/Input";
import FileInput from "@/components/Common/Inputs/FileInput/FileInput";
import TextArea from "@/components/Common/Inputs/TextArea/TextArea";
import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import CheckBox from "@/components/Common/Inputs/CheckBox/CheckBox";
import YesNoInput from "@/components/Common/Inputs/YesNoInput/YesNoInput";
import MakePremium from "@/components/Components/MakePremium/MakePremium/";
import PremiumAdSelection from "@/components/Components/PremiumAdSelection/PremiumAdSelection/";
import AdminPropertyListItem from "@/components/Components/AdminPropertyListItem/AdminPropertyListItem/";
import CreditWallet from "@/components/Components/CreditWallet/CreditWallet/";
import AdUpdate from "@/components/Components/AdUpdate/AdUpdate/";
import Pagination from "@/components/Components/Pagination/Pagination/";
import UserTypeMobile from "@/components/Header/HeaderMobile/UserTypeMobile/UserTypeMobile";
import UserNavMobile from "@/components/Header/HeaderMobile/UserNavMobile/UserNavMobile";
import PagePreloader from "@/components/Components/PagePreloader/PagePreloader.vue";
import RoommateAd from "@/components/Components/RoommateAd";
import SortingDropdown from "@/components/Components/SortingDropdown/SortingDropdown";


export default {
    name: 'AdminPropertyList',
    components: {
        SortingDropdown,
        RoommateAd,
        PagePreloader,
        Button,
        CheckBox,
        MakePremium,
        AdUpdate,
        PremiumAdSelection,
        CreditWallet,
        AdminPropertyListItem,
        TextArea,
        Dropdown,
        InputField,
        UserTypeMobile,
        UserNavMobile,
        YesNoInput,
        FileInput,
        Pagination,
    },
    data() {
        return {
            isLoading: false,
            filterBoxActive: false,
            activeInactiveSwitch: 'active',
            sortingOptions: [
                {
                    title: this.$t('price-ascending'),
                    value: 'rentPrice.ASC',
                },
                {
                    title: this.$t('price-descending'),
                    value: 'rentPrice.DESC',
                },
                {
                    title: this.$t('date-added'),
                    value: 'createdAt.DESC',
                },
            ],
            selectedOption: {
                title: this.$t('choose-period'),
                value: '',
            },
            properties: null,
            totalProperties: 0,
            offset: 0,
            limit: 5,
            filters: {
                active: true,
                showRequested: false,
                deleted: false,
                premium: false,
            },
            orderBy: null,
            currentPage: 1
        };
    },
    watch: {
        filters: {
            async handler(newVal, oldVal) {
                await this.loadProperties();
            },
            deep: true,
            immediate: true
        },
        activeInactiveSwitch: async function (newVal, oldVal) {
            this.filters.active = newVal === 'active';
            await this.loadProperties();
        },
    },
    methods: {
        async selectOption(option) {
            this.selectedOption = option;
            if (option.value === 'price_ascending') {
                this.orderBy = 'rentPrice.ASC';
            }
            if (option.value === 'price_descending') {
                this.orderBy = 'rentPrice.DESC';
            }
            if (option.value === 'create_date') {
                this.orderBy = 'createdAt.DESC';
            }
            await this.loadProperties();
            this.filterBoxActive = false;
        },
        async handlePageChange(page) {
            this.currentPage = page;
            this.offset = (this.currentPage - 1) * this.limit;
            await this.loadProperties();
        },
        async handleItemDeleted(propertyId) {
            if (confirm("Сигурни ли сте че искате да изтриете този имот?")) {
                await this.$store.dispatch('property/deleteProperty', propertyId)
                await this.loadProperties();
                await this.$store.dispatch('utils/openNotificationModal', {
                    type: 'success', message: 'Имотът беше изтрит успешно!'
                });
            }
        },
        async loadProperties() {
            this.isLoading = true;
            try {
                const result = await this.$store.dispatch("property/getUserProperties", {
                    offset: this.offset,
                    limit: this.limit,
                    orderBy: this.orderBy,
                    showDeleted: this.filters.deleted,
                    showPremium: this.filters.premium,
                    showRequested: this.filters.showRequested,
                    active: this.filters.active,
                });
                this.properties = result.data;
                this.totalProperties = result.total;
                this.offset = result.offset;
            } catch (err) {

            } finally {
                this.isLoading = false;
            }
        },
        async handleSorting(order) {
            this.orderBy = order;
            this.loadProperties();
        },
    },
    async mounted() {
        const page = this.$route.query.page ?? 1;
        this.offset = page === 1 ? 0 : (page - 1) * this.limit;

        if (this.$store.state.user.info.type === 'vendor') {
            this.$router.push({name: 'NotFoundPage'})
        }

        await this.loadProperties();
    },
};
