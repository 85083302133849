<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div class="container" v-if="isLoading">
    <PagePreloader/>
  </div>
  <div class="container" v-else>
    <div class="row" v-if="properties">
      <div class="col-md-12 inquiry-wrapper" v-bind:class="{'open-filter-box': filterBoxActive}">
        <div class="add-property-title d-md-flex align-items-baseline">
          <div> {{ $t('agency-my-offers-title') }}</div>
          <div class="d-flex ms-md-auto properties-sort-wrapper">
            <div class=" properties-sort-nav"
                 @click="activeInactiveSwitch = 'active'"
                 :class="{ 'active-button': activeInactiveSwitch === 'active' }">
              {{ $t('active') }}
            </div>
            <div class="properties-sort-nav"
                 @click="activeInactiveSwitch = 'inactive'"
                 :class="{ 'active-button': activeInactiveSwitch === 'inactive' }"
            > {{ $t('inactive') }}
            </div>
            <div class="d-flex ms-auto sort-filter d-md-none">
              <SortingDropdown :sorting-options="sortingOptions" :selected-value="orderBy" @sort="handleSorting"/>
            </div>
          </div>

        </div>
        <div class="d-flex align-items-center position-relative"
             v-bind:class="{'open-filter-box': filterBoxActive}"
        >
          <div class="d-flex col-md-9">
            <div class="checkbox d-flex align-items-start me-2">
              <CheckBox v-model="filters.showRequested" :checked="filters.showRequested"/>
              <div class="checkbox-label"> {{ $t('with-viewing-requests') }}</div>
            </div>
            <div class="checkbox d-flex align-items-start me-2">
              <CheckBox v-model="filters.deleted" :checked="filters.deleted"/>
              <div class="checkbox-label"> {{ $t('deleted-properties') }}</div>
            </div>
            <div class="checkbox d-flex align-items-start">
              <CheckBox v-model="filters.premium" :checked="filters.premium"/>
              <div class="checkbox-label"> {{ $t('premium') }}</div>
            </div>
          </div>
          <div class=" ms-auto  sort-filter d-none d-md-flex">
            <SortingDropdown :sorting-options="sortingOptions" :selected-value="orderBy" @sort="handleSorting"/>
          </div>
        </div>
        <div v-if="properties.length > 0">
          <div class="inquiry-item" v-for="property in properties">
            <AdminPropertyListItem
              :id="property.id"
              :date="property.createdAt"
              :propertyid="property.propertyid"
              :title="property.title"
              :adnumber="property.number"
              :image="property.images.length > 0 ? property.images[0].src : null"
              :hidePreviewButton="showDeletedOnly"
              :hideDeleteButton="showDeletedOnly"
              :is-vip="property.isVip"
              @itemDeleted="handleItemDeleted"
            />
          </div>
          <div class="pagination">
            <Pagination
              :size="'small'"
              :pages="Math.ceil(totalProperties/limit)"
              :route="'AdminPropertyList'"
              :current="currentPage"
              @pageChanged="handlePageChange"
            />
          </div>
        </div>
        <div v-else>
          <div class="alert alert-warning mt-3" role="alert">
            {{ $t('no-properties-found') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
